.destiantion-search-input-root {
  position: relative;
}
.destination-search-input-label {
  margin: 0;
}
@media only screen and (max-width: 1200px) {
  .destiantion-search-input-root {
    margin: 30px 0;
  }
}

@media only screen and (max-width: 1024px) {
  .destiantion-search-input-root {
    margin-left: auto;
  }
}
