.footer-contact {
  margin-top: 51px;
  margin-bottom: 40px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.email-contact {
  display: block;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0px 80px 0px 0px;
}

.email-text,
.mobile-contact-text {
  display: block;
  color: #a0a0a0;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  margin-bottom: 5px;
}

.email-address {
  color: #fff;
  margin-bottom: 0px;
}

.mobile-contact {
  color: #fff;
  display: block;
  align-items: center;
  justify-content: center;
  margin: 0px 30px 0px 0px;
}

.full-mobile-text {
  color: #a0a0a0;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  margin-bottom: 5px;
}

.mobile-contact-number {
  margin-bottom: 0px;
}

@media (max-width: 900px) {
  .footer-contact {
    flex-direction: column;
    font-size: 12px;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 20px 0px 0px 0px;
    height: fit-content;
  }

  .email-contact {
    margin-bottom: 20px;
  }

  .email-text {
    margin-bottom: 0px;
  }

  .full-email-text {
    display: none;
  }

  .short-text {
    display: flex;
    color: #a0a0a0;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
  }

  .mobile-contact {
    margin-left: 0px;
  }

  .full-mobile-text {
    display: none;
  }

  .mobile-contact-text {
    margin-bottom: 0px;
    font-size: 10px;
  }
}
