.footer-container {
  background: #3d5881;
  width: auto;
  height: auto;
  padding-bottom: 30px;
}

.footer-section-1 {
  background: #181818;
  display: flex;
  margin: 0px 60px 0px 60px;
  align-content: center;
}

.footer-section-2 {
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px;
  margin: 30px 60px 0px 60px;
}

.footer-logo-container {
  padding: 40px;
}

.footer-social-links-wrapper {
  width: 140px;
  display: flex;
  justify-content: space-between;
}

.footer-social-links {
  cursor: pointer;
}

.footer-text {
  font-size: 12px;
  font-family: "Rubik", sans-serif;
  font-weight: 300;
  line-height: 22px;
}

.footer-text > a,
.footer-text > a:link,
.footer-text > a:visited {
  color: #fff;
}
.footer-logo {
  cursor: pointer;
}
@media (max-width: 900px) {
  .footer-section-1 {
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
    justify-content: space-around;
    margin: 0 15px;
    padding: 20px 10px;
  }

  .footer-logo-container {
    padding: 10px 0px 0px 0px;
  }

  .footer-logo {
    text-decoration: none;
    color: #a0a0a0;
    text-align: justify;
    font-size: 12px;
    width: 128px;
  }

  .footer-nav-and-contact-wrapper {
    display: flex;
    padding: 0px;
    max-width: 100%;
  }

  .footer-section-2 {
    flex-direction: column;
    align-items: center;
    font-size: 12px;
  }

  .footer-social-links-wrapper {
    justify-content: space-around;
    padding-bottom: 10px;
  }
}
@media (max-width: 450px) {
  .footer-nav-and-contact-wrapper {
    display: flex;
    flex-direction: row;
  }
}
