.day-tour-section {
  padding-top: 50px;
  margin: 200px 0;
  scroll-margin-top: 80px;
  overflow: hidden;
}

.day-tour-content-section {
  display: flex;
}

.day-tour-content-section-expand {
  flex-wrap: wrap;
}

.day-tour-card {
  width: 200px;
  margin: 20px 0px;
  margin-right: 20px;
}

.day-tour-card-expand {
  width: 320px;
}

.day-tour-card-image {
  width: 200px;
  height: 320px;
  position: relative;
  overflow: hidden;
}

.day-tour-card-image img {
  position: absolute;
}

.day-tour-card-image-expand {
  width: 315px;
}

.day-tour-card-image-expand img {
  width: 100%;
}

.view-more-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
}

.view-more {
  font-family: "Rubik";
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.04em;
  color: #ffffff;
  opacity: 0.6;
  margin-right: 10px;
}

.show-more-button.rotate-arrow {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}


.show-more-button {
  width: 64px;
  height: 64px;
}

@media (max-width: 900px){
  .day-tour-section {
    margin: 60px 0 35px;
  }

  .day-tour-header {
    font-family: "Rubik";
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.04em;
  }

  .content-head {
    width: 155px;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: #D0D0D0;
  }

  .content-place {
    width: 155px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.01em;
  }

  .show-more-button {
    width: 32px;
    height: 32px;
  }

}

@media (max-width: 767px) {
  
  .day-tour-card {
    max-width: inherit;
    margin-right:20px ;
    flex: 0 0 calc(50% - 10px);
  }

  .day-tour-card-expand {
    width: 155px;
  }

  .day-tour-card-expand:nth-child(even){
    margin-right: 0;
  }

  .day-tour-card-image {
    width: 155px;
    height: 110px;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
  }

  .day-tour-card-image img{
    width: 100%;
  }
  .day-tour-card {
    max-width: inherit;
    margin-right:20px ;
    flex: 0 0 calc(50% - 10px);
  }
}


.content-head {
  width: 155px;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #d0d0d0;
  }

.content-place {
  width: 155px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.01em;
  }
