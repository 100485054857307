.questions-root-container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 50px;
}

.faq-text-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.faq-text-container {
  font-family: Rubik;
  font-weight: 500;
  font-size: 20px;
  color: #a0a0a0;
  margin-bottom: 50px;
  cursor: pointer;
}
.faq-text-container .cat:last-child {
  margin-bottom: 0;
}
.faq-text-container .cat.active {
  color: #ffffff;
}
.category-right-arrow {
  margin-left: 20px;
}

.faq-categorize-questions p.active {
  color: #ffffff;
}

.faq-answers p.active {
  color: #ffffff;
}

@media only screen and (max-width: 760px) {
  .questions-root-container {
    grid-template-columns: repeat(1, 1fr);
  }

  .faq-text-container {
    margin-bottom: 0;
    font-size: 16px;
    line-height: 24px;
  }
}

@media only screen and (max-width: 600px) {
  .ant-col-10 {
    max-width: 100% !important;
  }
}
