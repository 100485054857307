.hotels-wrapper {
  min-height: 650px;
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  background: #3d5881;
}

.three-dots-loader {
  margin: auto;
  align-self: center;
}

.images-container {
  width: 100%;
}

.details-container {
  padding: 0px 30px;
  margin: 50px auto;
  max-width: 1200px;
}

.hotel-room-search-container {
  max-width: 1200px;
  margin: auto;
  width: 100%;
  padding: 0 30px;
}

.dedicated-hotel-title,
.hotel-room-title {
  font-size: 24px;
  line-height: 28px;
  margin-top: 10px;
}

.room-details,
.room-details div,
.room-details div > span,
.room-details p,
.dedicated-hotel-description,
.dedicated-hotel-description div,
.dedicated-hotel-description div > span,
.dedicated-hotel-description p {
  color: #a0a0a0 !important;
  font-size: 14px;
}

.dedicated-hotel-description {
  margin-top: 20px;
}

.hotel-details {
  display: flex;
  justify-content: space-between;
}
.dedicated-hotel-price-container {
  background-color: #D7DCE4;
  display: flex;
  max-width: max-content;
  padding: 10px;
  gap: 10px;
  align-items: center;
}

.dedicated-hotel-price-container .default-paragraph {
  margin: 0px;
}

.hotel-price-label {
  color: rgba(61, 88, 129, 0.6);
}

.hotel-price-info {
  color: rgba(61, 88, 129, 1);
}

.dedicated-hotel-rooms {
  margin: 20px 0px;
}

.hotel-rooms-container {
  display: flex;
  margin: 20px 0;
  border: 1px solid #a0a0a0;
  border-radius: 5px;
  padding: 10px;
}

.room-image {
  width: 300px;
}

.room-details {
  padding: 0px 20px;
}

.room-feature {
  margin: 0;
}