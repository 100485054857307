.guest-increment-root {
  padding: 10px;
  display: flex;
  justify-content: space-between;
}
.guest-increment-name-container p {
  margin: 0;
}
.guest-increment-name-container .guest-name,
.guest-subname,
.guest-buttons {
  color: #ffffff;
}
.guest-subname {
  opacity: 0.6;
}
.guest-buttons {
  display: flex;
  justify-content: space-around;
  width: 50%;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
.guest-sub-inc-button {
  width: 32px;
  height: 32px;
  background: rgba(255, 255, 255, 0.06);
  border: 0.75px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.guest-total-number {
  padding-top: 8px;
}
.guest-sub-inc-button:hover {
  background: rgba(255, 255, 255, 0.18);
}
.guest-sub-inc-button.disabled-guest-button {
  background: rgba(255, 255, 255, 0.06);
  opacity: 0.06;
  pointer-events: none;
}
