.PackageCard {
  width: 426px;
  margin: 80px 130px 10px 0;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.PackageCard .thumb {
  height: 240px;
  width: 426px;
  margin: 0;
}

/* .PackageCard .title {
  margin: 20px 0 0 0;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.01em;
  color: #d0d0d0;
} */

.PackageCard .description-container {
  margin: 40px 0 0 0;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.PackageCard .description-container::-webkit-scrollbar {
  display: none;
}

.PackageCard .info-container {
  display: flex;
  margin: 0;
}

.PackageCard .info-container .duration-container {
  margin: 0;
}

.PackageCard .info-container .price-container {
  margin: 0 0 0 84px;
}

@media (max-width: 800px) {
  .PackageCard {
    margin: 60px 60px 0 0;
    padding-right: 40px;
  }
}

@media (max-width: 500px) {
  .PackageCard {
    margin: 40px 40px 0 0;
    padding-right: 30px;
    width: 380px;
  }

  .PackageCard .thumb {
    height: 215px;
    width: 380px;
  }

  .PackageCard .description-container {
    margin: 20px 0 0 0;
    width: 380px;
  }

  .PackageCard .description-container .description {
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.01em;
    height: 60px;
    text-overflow: ellipsis;
  }
}

@media (max-width: 400px) {
  .PackageCard {
    margin: 40px 40px 0 0;
    padding-right: 30px;
    width: 310px;
  }

  .PackageCard .thumb {
    height: 175px;
    width: 310px;
  }

  .PackageCard .description-container {
    margin: 20px 0 0 0;
    width: 310px;
  }
}
