.default-title.hero-title.hero-title-header {
  font-weight: 400;
  font-size: 6.25vw;
  margin-bottom: 0;
  color: #fff;
  z-index: 1;
  white-space: nowrap;
  transform: translate(-20%, 45%);
}

.default-title.hero-title.hero-title-header.hero-title-firstline {
  color: transparent;
  -webkit-text-stroke: 1px #fff;
}

.default-title.services-title {
  font-size: 60px;
  font-weight: 400;
  letter-spacing: normal;
}

.default-title.search-results-packages-title,
.default-title.search-results-destination-title,
.default-title.search-results-day-tour-title
{
  font-size: 24px;
  line-height: 28px;
  margin-top: 10px;
}

.default-title.search-results-day-tour-location{
  font-size: 18px;
  line-height: 24px;
  margin-top: 10px;
  font-weight:normal;
  color: #D0D0D0;
}


.default-paragraph {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #a0a0a0;
}

.default-paragraph.services-description-1 {
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
  color: #A0A0A0;

  padding-right: 30px;
}

.default-paragraph.services-description-2 {
  max-width: 538px;
  font-size: 24px;
  line-height: 150%;
  color: #d0d0d0;
  padding-right: 30px;
}

.default-paragraph.service-card-title {
  margin: 20px 0;
  color: #D0D0D0;
}

.default-title {
  font-size: 60px;
  line-height: 70px;
  letter-spacing: 0.04em;
  color: #ffffff;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
}

.default-title.destinations-header {
  padding-left: 170px;
  padding-bottom: 70px;
}

.packages-header .default-title.header-title.packages-title {
  margin-left: 140px;
  margin-top: 0;
  padding-top: 35px;
  padding-bottom: 50px;
}

.PackageCard .default-title.title {
  margin: 20px 0 0 0;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.01em;
  color: #d0d0d0;
}

.PackageCard .default-paragraph.default-paragraph.description {
  font-size: 16px;
}

.PackageCard .info-container .label.default-paragraph {
  margin: 20px 0 0 0;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.04em;
  color: #ffffff;
  opacity: 0.6;
}

.PackageCard .info-container .info.default-paragraph {
  margin: 5px 0 0 0;
  font-size: 16px;
  color: #d0d0d0;
}

.destination-card .default-title.title {
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.01em;
  color: #d0d0d0;
  margin-top: 20px;
  margin-bottom: 40px;
}

.destination-card .description-container .default-paragraph.description {
  font-size: 16px;
  display: none;
}

.default-title.day-tour-header {
  margin-left: 110px;
}

.day-tour-content-section .content-head.default-paragraph {
  font-size: 24px;
  line-height: 28px;
  color: #d0d0d0;
  margin: 10px 0px;
  width: 100%;
}

.day-tour-content-section .content-place.default-paragraph {
  font-size: 16px;
  width: 100%;
}

.default-paragraph.show-more-text {
  margin: 0;
  margin-right: 10px;
  font-size: 12px;
  color: #ffffff;
  opacity: 0.6;
}

.default-paragraph.section-header {
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.04em;
  color: #fff;
}

.default-paragraph.white {
  color: #fff;
}
.default-paragraph.hero-paragraph {
  font-weight: 300;
  font-size: 18px;
}

.default-paragraph.card-title {
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.01em;
}
.default-paragraph.card-description {
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.01em;
  font-weight: normal;
  color: #a0a0a0;
}

.default-paragraph.dervices-description-1 {
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  padding-right: 30px;
}

.typography-link {
  display: inline-block;
  text-decoration: none;
}

.typography-link .default-paragraph.header-link-each {
  width: 100%;
  height: 100%;
  padding: 0 30px;
  text-decoration: none;
  text-align: center;
  line-height: 80px;
  white-space: nowrap;
  font-size: 14px;
  margin-bottom: 0;
}

@media only screen and (max-width: 900px) {
  .day-tour-content-section .content-head.default-paragraph {
    font-size: 20px;
    line-height: 23px;
    font-weight: 400;
  }

  .default-title.services-title {
    font-size: 24px;
    line-height: 28px;
  }

  .default-paragraph.services-description-1 {
    font-size: 16px;
    line-height: 24px;
    padding-right: 0;
    margin-bottom: 20px;
  }

  .default-paragraph.services-description-2 {
    font-size: 14px;
    line-height: 21px;
    padding-right: 0;
  }

  .default-paragraph.service-card-title  {
    font-size: 14px;
    line-height: 21px;
  }

  .packages-header .default-title.header-title.packages-title {
    margin:0 24px;
    padding:20px 0
  }
}

@media only screen and (max-width: 720px) {
  .default-title.hero-title {
    font-size: 36px;
    line-height: 43px;
  }
}
