@import "~antd/dist/antd.css";
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
}
body {
  background-color: #181818;
  font-family: "Rubik", sans-serif;
}
.section-padding {
  padding: 0 60px;
}

.App.open-modal {
  position: fixed;
}

@media (max-width: 768px) {
  .section-padding {
    padding: 0 15px;
  }
}
