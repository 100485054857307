.destination-cards-container {

}
.destination-card {
  width: 205px;
  height: 500px;
  transition: all 500ms ease;
}

.destination-card.active {
  width: calc(100% - 50px);
  transition: all 250ms ease-in-out;
}

.destination-card-image {
  width: 205px;
  height: 240px;
  position: relative;
  overflow: hidden;
}

.destination-card .thumb {
  position: absolute;
}

.destination-card.active .destination-card-image {
  width: 100%;
}

.destination-card .title {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.01em;
  color: #d0d0d0;
  margin-top: 20px;
  margin-bottom: 40px;
}

.destination-card .description-container {
  height: 120px;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.destination-card .description-container::-webkit-scrollbar {
  display: none;
}

.destination-card .description-container .description {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #a0a0a0;
  display: none;
}

.destination-card.active .description-container .description {
  display: initial;
}
@media only screen and (max-width: 500px) {
  .destination-card {
    height: auto;
    width: 275px;
    margin-right: 10px !important;
  }

  .destination-card.active {
    width: 275px;
    margin-right: 10px !important;
  }

  .destination-card-image {
    width: 100%;
    height: 110px;
  }
  .destination-card .thumb {
    width: 100%;
    top: -20px;
  }

  .destination-card.active .thumb {
    width: 100%;
  }

  .destination-card .description-container {
    height: auto;
  }

  .destinations-carousel-root .destinations-carousel-showmore.destinations-carousel-showmore {
    padding-left: 20px;
  }
}
