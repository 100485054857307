.add-guests-modal-root {
  width: 325px;
  background: #3d5881;
  border: 1px solid #3f87f3;
  box-sizing: border-box;
  position: absolute;
  top: 80px;
  left: 0;
  z-index: 20;
}
