.header {
  z-index: 2;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #181818;
  height: 80px;
  position: fixed;
  top: 0;
  font-family: "Rubik", sans-serif;
  z-index: 2;
}
.header-links-mobile {
  display: none;
}
.header-link-menu {
  display: none;
}
.header-logo {
  margin-left: 60px;
  cursor: pointer;
  height: 100%;
  width: 120px;
}
.header-links {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.header-nav-link,
.header-lanugage-link,
.header-link-each {
  color: #a0a0a0;
}
.header-nav-link {
  margin-right: 60px;
  text-decoration: none;
}
.header-lanugage-link {
  margin: 0;
  text-transform: uppercase;
}
.header-link-each {
  text-decoration: none;
  text-align: center;
  font-size: 14px;
}
.header-link-last {
  margin-right: 30px;
}

.header-link-each:hover {
  color: #fff;
  background-color: #ffffff08;
}
.header-langmenu,
.header-langmenu-mobile {
  background-color: #181818;
}

.header-global {
  color: #a0a0a0;
  font-size: 20px;
  margin: 0 15px;
}
.header-langmenu-container {
  display: flex;
  align-items: center;
  position: relative;
  margin-right: 60px;
  cursor: pointer;
  margin-left: 70px;
}
.header-langmenu-container:hover {
  background-color: #ffffff08;
}
.search-icon-container {
  position: relative;
  margin-left: 10px;
}
.custom-arrow {
  color: #a0a0a0;
  margin-left: 10px;
}
.header-langmenu-button {
  display: flex;
  align-items: center;
  justify-content: center;
}
.header-langmenu-dropdown {
  background-color: #181818;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50px;
  left: -10px;
}
.header-search-container {
  position: absolute;
  top: 100%;
  right: 0;
  width: max-content;
}
.search-result-close-icon {
  margin-right: 50px;
  cursor: pointer;
}

@media (max-width: 980px) {
  .header {
    z-index: 2;
  }

  .header-links {
    display: none;
  }

  .header-link-menu {
    display: block;
    height: 100%;
    padding: 0 30px;
    line-height: 80px;
    color: white;
    font-size: 30px;
  }

  .header-links-mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 60px;
    right: 10px;
    background-color: #181818;
  }
  .header-langmenu {
    display: none;
  }
  .header-link-menu-mobile {
    width: 100%;
  }
  .header-langmenu-mobile {
    display: block;
    width: 100%;
    margin-right: 0;
    padding-left: 35px;
  }
  .header-langmenu-mobile:hover {
    background-color: #ffffff08;
  }
}
@media (max-width: 580px) {
  .header-global {
    display: none;
  }
  .header-logo {
    margin: 0 10px;
  }
  .header-link-menu {
    padding: 0;
    padding-right: 10px;
  }
  .header-langmenu-container {
    margin-right: 10px;
  }
}
