.SearchResultsDayTourCard {
  display: flex;
  margin: 30px 20px;
  margin: 30px 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.06);
  padding-bottom: 30px;
  cursor: pointer;
}

.SearchResultsDayTourCard .day-tour-information {
  padding-left: 20px;
  width: 100%;
}

.SearchResultsDayTourCard .day-tour-image-container .day-tour-image {
  width: 300px;
  height: 270px;
  object-fit: cover;
}

@media (max-width: 600px) {
  .SearchResultsDayTourCard {
    flex-direction: column;
  }

  .SearchResultsDayTourCard .day-tour-image-container .day-tour-image {
    width: 100%;
    height: 270px;
    object-fit: cover;
  }

  .SearchResultsDayTourCard .day-tour-information {
    padding: 0;
  }
}
