.search-input-modal-root::-webkit-scrollbar {
  width: 6px;
}

.search-input-modal-root::-webkit-scrollbar-track {
  background: #808080;
}

.search-input-modal-root::-webkit-scrollbar-thumb {
  background-color: #0e274e;
  border-radius: 20px;
  border: 2px none #b0b0b0;
}

.search-input-modal-root {
  width: 325px;
  max-height: 275px;
  position: absolute;
  top: 80px;
  left: 0;
  z-index: 10;
  background: #3d5881;
  border: 1px solid #3f87f3;
  box-sizing: border-box;
  overflow: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: #0e274e #808080;
}
.search-input-modal-title-container {
  background: #2c405d;
}
.search-input-modal-title-container h3,
.search-recomended-place {
  margin: 0;
  font-size: 14px;
  color: #ffffff;
}
.search-map-svg {
  margin-right: 10px;
}
.search-input-modal-title-container,
.search-recomended-place {
  padding: 0px 10px;
  display: flex;
  text-transform: capitalize;
  cursor: pointer;
  align-items: center;
  height: 50px;
}
.recomended-city-name {
  margin: 0;
}
.search-bar-right-side {
  display: flex;
  align-items: center;
}
