.footer-links {
  margin-top: 30px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.footer-link-each,
.footer-link-each:visited,
.footer-link-each:link {
  text-decoration: none;
  color: #A0A0A0;
  text-align: center;
  font-size: 14px;
  width: 128px;
}

@media (max-width:900px) {
  .footer-links{
    flex-wrap: wrap;
    height: fit-content;
    margin: 20px 0px 30px 0px;
  }

  .footer-link-each,
.footer-link-each:visited,
.footer-link-each:link {
  text-decoration: none;
  color: #A0A0A0;
  text-align: justify;
  font-size: 12px;
  width: 128px;
  padding-bottom: 5px;
  

  flex-direction: column;
  
}

}
  