.services {
  padding: 25px 50px;
  scroll-margin-top: 80px;
}

.ant-col-14.services-img-container {
  color: #fff;
  font-family: "Rubik", sans-serif;
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
  display: grid;
  grid-template-columns: repeat(3, minmax(190px, 1fr));
  column-gap: 30px;
  row-gap: 50px;
  margin: 0px 0px 30px 0px;
  justify-items: center;
}

.services-faq {
  width: 100%;
  max-width: 100%;
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 150%;/* or 36px */
  letter-spacing: 0.01em;
  color: #D0D0D0;
}

@media (max-width: 1320px) {
  .ant-row.services-row {
    flex-direction: column;
  }
  .ant-col-10.services-description-container {
    max-width: 100%;
  }
  .ant-col-14.services-img-container {
    max-width: 100%;
  }
  .service-card .service-card-img {
    max-width: 100%;
  }
  .services-description-paragraph {
    padding-right: 0;
  }

  .default-paragraph.services-description-2 {
    margin-bottom: 20px;
  }
}
@media (max-width: 820px) {
  .ant-col-14.services-img-container {
    grid-template-columns: repeat(2, minmax(190px, 1fr));
    row-gap: 0;
    margin: 0px 0px 40px 0px;
  }
  .services {
    padding: 25px 15px;
  }
  .ant-col-14.services-img-container {
    column-gap: 10px;
    grid-template-columns: repeat(2, minmax(140px, 1fr));
  }
}
