.PackagesCarousel {
  margin-bottom: 50px;
  scroll-margin-top: 79px;
}

.PackagesCarousel .carousel {
  margin: 0;
  padding-left: 60px;
  padding-right: 60px;
  display: flex;
  overflow-x: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  scroll-behavior: smooth;
  min-height: 700px;
}

.PackagesCarousel .carousel::-webkit-scrollbar {
  display: none;
}

.PackagesCarousel .packages-header {
  background: #181818;
  margin: 0 60px;
}

.PackagesCarousel .packages-header .header-title {
  margin-left: 140px;
  margin-top: 0;
  padding-top: 35px;
  padding-bottom: 50px;
  font-family: Rubik;
  font-size: 60px;
  line-height: 71px;
  letter-spacing: 0.04em;

  color: #ffffff;
}

.PackagesCarousel .packages-header .tabs {
  margin-bottom: 0;
  margin-left: 110px;
  display: flex;

  font-family: Rubik;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.04em;
  white-space: nowrap;
}

.PackagesCarousel .packages-header .tabs .tab {
  width: fit-content;
  color: #a0a0a0;
  margin: 0 30px;
  border-bottom: 5px solid #181818;
  padding-bottom: 20px;
  box-sizing: border-box;
  cursor: pointer;
}

.PackagesCarousel .packages-header .tabs .tab.active {
  border-bottom: 5px solid #ffffff;
  font-weight: 500;
  color: #fff;
}

.PackagesCarousel .show-more-button-section {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.PackagesCarousel .show-more-button-section .container {
  margin: 0 60px 60px 0;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.PackagesCarousel .show-more-button-section .show-more-arrow.left {
  margin-right: 10px;
}

.PackagesCarousel .show-more-button-section .show-more-arrow.right {
  margin-left: 10px;
}

.PackagesCarousel .show-more-button-section .show-more-text {
  margin: 0;
  font-family: Rubik;
  font-size: 12px;
  color: #ffffff;
  opacity: 0.6;
}

.PackagesCarousel .show-more-button-section .show-more-text.back-button-only {
  margin-right: 74px;
}

@media (max-width: 1250px) {
  .PackagesCarousel .packages-header {
    margin: 0 25px;
  }

  .PackagesCarousel .packages-header .header-title {
    margin-left: 50px;
    margin-top: 0;
    padding-top: 35px;
    margin-bottom: 20px;
    padding-bottom: 10px;
  }

  .PackagesCarousel .packages-header .tabs {
    overflow-x: scroll;
    margin: 0;
  }

  .PackagesCarousel .packages-header .tabs .tab {
    width: min-content;
  }

  .PackagesCarousel .packages-header .tabs {
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.04em;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.04em;
  }

  .PackagesCarousel .carousel {
    padding-left: 20px;
    padding-right: 20px;
    min-height: 625px;
  }

  .PackagesCarousel .show-more-button-section .container {
    margin: 20px 60px 40px 0;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
}

@media (max-width: 500px) {
  .PackagesCarousel .packages-header {
    margin: 0 15px;
  }

  .PackagesCarousel .packages-header .header-title {
    margin-left: 20px;
    margin-top: 0;
    padding-top: 25px;
    padding-bottom: 10px;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.04em;
  }

  .PackagesCarousel .packages-header .tabs {
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.04em;
  }

  .PackagesCarousel .packages-header .tabs .tab {
    padding-bottom: 10px;
  }

  .PackagesCarousel .carousel {
    min-height: 530px;
    padding-left: 15px;
  }

  .PackagesCarousel .show-more-button-section .container {
    margin: 0 30px 30px 0;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  .PackagesCarousel .show-more-button-section {
    margin-top: 30px;
  }

  .PackagesCarousel .packages-header .tabs .tab {
    margin: 0 15px;
  }

  .PackagesCarousel .packages-header .tabs div:first-child .tab {
    margin-left: 30px;
  }
}

@media (max-width: 400px) {
  .PackagesCarousel .packages-header {
    margin: 0 15px;
  }

  .PackagesCarousel .carousel {
    min-height: 500px;
    padding-left: 15px;
  }
}
