.SearchResultsDestinationCard {
  display: flex;
  margin: 30px 20px 20px 25px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.06);
  padding-bottom: 30px;
  cursor: pointer;
}

.SearchResultsDestinationCard .destination-image-container {
  width: 300px;
  height: 225px;
}

.SearchResultsDestinationCard .destination-image-container .destination-image {
  width: 300px;
  height: 225px;
}

.SearchResultsDestinationCard .destination-information {
  margin-left: 20px;
}

.destination-information,
.destination-information *,
.destination-information * > span {
  color: #a0a0a0  !important;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  line-height: 22px;
  font-size: 14px;
  text-shadow: none;
}

.destination-information div::-webkit-scrollbar,
.destination-information p::-webkit-scrollbar {
  display: none;
}

.destination-information .price-container {
  width: 100px;
  margin-left: auto;
}

.destination-information .price-container .label {
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.04em;
}

.destination-information .price-container .info {
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #ffffff;
}

@media (max-width: 1000px) {
  .SearchResultsDestinationCard {
    margin: 15px 20px;
    width: initial;
  }
}

@media (max-width: 600px) {
  .SearchResultsDestinationCard {
    flex-direction: column;
    border-bottom: initial;
  }

  .SearchResultsDestinationCard .destination-image-container {
    width: initial;
    height: 150px;
    display: flex;
    justify-content: space-between;
  }

  .SearchResultsDestinationCard .destination-image-container .destination-image {
    object-fit: cover;
    width: 100%;
    height: 150px;
  }
  .SearchResultsDestinationCard .destination-information {
    margin-left: 0;
  }
}

@media (max-width: 350px) {
  .SearchResultsDestinationCard {
    margin: 0 15px;
  }
  .SearchResultsDestinationCard .destination-image-container {
    flex-direction: column;
    height: initial;
  }
}
