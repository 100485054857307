.destinations-carousel-root {
  margin-top: 120px;
}

.destinations-carousel-root .destinations-carousel {
  display: flex;
  padding-left: 60px;
  overflow: hidden;
}

.destinations-carousel-root .destinations-carousel-showmore {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  padding-left: 60px;
}

.destinations-carousel-root .show-more-button-wrapper {
  display: flex;
  justify-content: flex-end;
}

.destinations-carousel-root .show-more-button-wrapper .show-more-container {
  margin: 0 60px 60px 0;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.destinations-carousel-root .show-more-button-wrapper .show-more-text {
  margin: 0;
  margin-right: 10px;
  font-family: Rubik;
  font-size: 12px;
  color: #ffffff;
  opacity: 0.6;
}

.destinations-carousel-root .rotate-arrow {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

@media only screen and (max-width: 1360px) {
  .destinations-carousel-root .destinations-carousel-showmore {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 960px) {
  .destinations-carousel-root .destinations-header {
    padding-left: 60px;
  }
  .destinations-carousel-root .destination-cards-container {
    margin: 16px;
  }
  .destinations-carousel-root .destinations-carousel-showmore {
    grid-template-columns: repeat(1, 1fr);
  }
  .destinations-carousel-root {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 500px) {
  .destinations-carousel-root .destinations-carousel {
    padding-left: 16px;
  }
  .destinations-carousel-root .destinations-header {
    padding:0 32px 20px 32px;
    margin-bottom: 0;
    font-size: 40px;
    line-height: 45px;
  }
  .destinations-carousel-root .show-more-button-wrapper .show-more-container {
    margin: 25px 30px 20px 0;
  }
}
