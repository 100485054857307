.search-results {
  position: fixed;
  display: flex;
  flex-direction: column;
  margin: auto;
  left: 2.5%;
  width: 95%;
  height: 98%;
  background: #3d5881;
  z-index: 10;
  overflow: hidden;
}

.search-results .header-section {
  margin: 0 25px 0 25px;
}

.search-results .header-section .headings {
  display: flex;
}

.search-results .results {
  display: flex;
  height: calc(100% - 245px);
}

.search-results .results .packages-container {
  width: 60%;
  height: 100%;
  overflow: scroll;
  -ms-overflow-style: none; /*IE and Edge*/
  scrollbar-width: none; /* Firefox */
  scroll-behavior: smooth;
}

.search-results .results .mapbox-container {
  width: 40%;
  margin: 30px 25px 0px 0px;
}
.search-results .results .mapbox-container .mapboxgl-canvas-container,
.search-results .results .mapbox-container .map-container,
.search-results .results .mapbox-container .mapboxgl-canvas-container canvas {
  height: 500px !important;
}

.search-results .results .packages-container::-webkit-scrollbar {
  display: none;
}

.search-results .filter-section {
  display: flex;
  height: 70px;
  align-items: center;
}

.search-results .filter-section .filter-title-label {
  display: flex;
  align-items: center;
}

.search-results .filter-section .filter-types-container {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
}

.search-results .filter-section .filter-types-container .filter-sort-container {
  display: flex;
  justify-content: flex-end;
  flex-grow: 10;
}

.search-results .filter-section .filter-types-container .filter-type-tab {
  background: rgba(255, 255, 255, 0.06);
  border: 0.75px solid rgba(255, 255, 255, 0.1);
  color: #ffffff;
  opacity: 0.6;

  height: 22px;
  padding: 4px 6px;

  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.04em;
  cursor: pointer;
}

.search-results .filter-section .filter-types-container .filter-type-tab:not(.right) {
  margin: 0 10px;
}

.search-results .filter-section .filter-types-container .filter-type-tab.selected {
  color: #3d5881;
  background: rgba(255, 255, 255, 0.8);
  border: 0.75px solid rgba(255, 255, 255, 0.1);
  opacity: 1;
}

.search-results .filter-section .filter-types-container .clear-filters {
  cursor: pointer;
  margin: 0;
}

.search-results .filter-section .filter-title-label .filter-text {
  margin: 0 40px 0 10px;
}

.loading-text {
  text-align: center;
  color: #fff;
}

@media (max-width: 1000px) {
  .search-results {
    margin: 0 20px;
  }
  .search-results .results {
    flex-direction: column;
  }

  .search-results .results .mapbox-container {
    order: -1;
  }

  .search-results .results .packages-container {
    width: 100%;
  }

  .search-results .results .mapbox-container .mapboxgl-canvas-container,
  .search-results .results .mapbox-container .map-container,
  .search-results .results .mapbox-container .mapboxgl-canvas-container canvas {
    height: 300px !important;
  }

  .search-results .results .mapbox-container {
    width: 100%;
  }
}

.three-dots-loader {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1200px) {
  .search-results .filter-section .filter-types-container .filter-type-tab {
    margin: 10px 10px;
  }
}
@media (max-width: 350px) {
  .search-results {
    margin: 0 10px;
  }
}
