.destination-search-bar-root {
  margin-top: 80px;
  padding: 15px 60px;
  display: flex;
  justify-content: space-between;
}

.destination-search-bar-root.modal {
  padding: 15px 25px;
}
.destination-search-input,
.destination-search-input-label {
  text-transform: capitalize;
}
.destination-search-input-label {
  font-size: 12px;
  color: #ffffff;
  opacity: 0.6;
}
.destination-search-input {
  background-color: transparent;
  border: none;
  outline: none;
  height: 36px;
  font-size: 24px;
  color: rgba(255, 255, 255, 0.6);
}
.destination-search-input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.2);
}
.destination-search-input:-moz-placeholder {
  color: rgba(255, 255, 255, 0.2);
}
.destination-search-input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.2);
}
.destination-search-input:focus::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.6);
}
.destination-search-input:focus:-moz-placeholder {
  color: rgba(255, 255, 255, 0.6);
}
.destination-search-input:focus::-moz-placeholder {
  color: rgba(255, 255, 255, 0.6);
}
.ant-picker.ant-picker-borderless {
  margin-left: auto;
  margin-right: 50px;
  position: relative;
  top: 10px;
  padding: 0;
}
.header-search-container .rangepickerinput {
  background-color: #181818;
  border: none;
}
.header-search-container .rangepickerinput input {
  color: #a0a0a0;
  font-size: 24px;
  line-height: 36px;
}
.header-search-container .rangepickerdropdown {
  background-color: #3d5881;
}
.rangepickerdropdown .ant-picker-header {
  background-color: #2c405d;
  border-bottom: 0;
}
.rangepickerinput.show-date-err .ant-picker-input > input {
  border-bottom: 2px solid red;
}
.ant-picker-input > input .rangepickerdropdown .ant-picker-panel {
  border-bottom: none;
}
.ant-picker-range .ant-picker-active-bar {
  display: none;
}
.rangepickerdropdown
  .ant-picker-cell-in-view
  + .ant-picker-cell-in-range
  .ant-picker-cell-inner {
  color: #000;
}
.rangepickerdropdown
  .ant-picker-cell-in-view
  + .ant-picker-cell-start
  + .ant-picker-cell-in-range
  .ant-picker-cell-inner {
  color: #fff;
}
.rangepickerdropdown .ant-picker-cell-in-range .ant-picker-cell-inner::before {
  background-color: #fff;
}

.rangepickerdropdown .ant-picker-panel-container {
  background-color: #3d5881;
}
.rangepickerdropdown .ant-picker-cell-disabled::before {
  background-color: #3d5881;
}
.rangepickerdropdown .ant-picker-panel * {
  color: #fff;
}
.rangepickerdropdown
  .ant-picker-cell-in-view.ant-picker-cell-in-range
  > .ant-picker-cell-inner {
  color: #000;
}
.rangepickerdropdown .ant-picker-cell-range-start {
  color: #000;
}
.rangepickerdropdown .ant-picker-footer-extra {
  padding: 0;
  margin: 0;
  color: #fff;
}
.rangepicker-footer {
  background-color: #2c405d;
  text-align: center;
  display: flex;
  justify-content: center;
}
.rangepicker-footer .rangepicker-footer-withdates {
  display: flex;
}
.rangepickerinput {
  padding: 0 15px;
  background-color: transparent;
  align-items: flex-end;
}
.rangepickerinput .ant-picker-focused {
  box-shadow: none;
}
.rangepickerinput > div::before {
  position: absolute;
  font-size: 12px;
  top: -1.5em;
  left: 0;
  color: rgba(255, 255, 255, 0.6);
}
.rangepickerinput > div:nth-child(1)::before {
  content: "Check-in";
}
.rangepickerinput > div:nth-child(3)::before {
  content: "Check-out";
}
.rangepickerinput .ant-picker-input > input::placeholder {
  font-size: 24px;
  color: rgba(255, 255, 255, 0.1);
}
.rangepickerinput .ant-picker-input > input {
  height: 36px;
  font-size: 24px;
  color: rgba(255, 255, 255, 0.6);
}
.rangepickerinput .ant-picker-input {
  width: 120px;
}
.search-icon {
  cursor: pointer;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 1px solid #a0a0a0;
}
.tailspin-loader-container,
.tailspin-loader-container > div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.tailspin-loader-container {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 1px solid #a0a0a0;
}

@media only screen and (max-width: 1200px) {
  .rangepickerinput {
    padding: 0;
  }
}

@media only screen and (max-width: 1024px) {
  .ant-picker.ant-picker-borderless {
    margin-left: 0;
    margin-right: 0;
  }

  .rangepickerinput {
    margin-right: auto;
  }

  .destination-search-bar-root {
    flex-direction: column;
  }
}
