.hero {
  position: relative;
  height: calc(100vh - 80px);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.hero-title {
  position: absolute;
  top: -9.5vw;
  left: 10%;
  z-index: 1;
  white-space: nowrap;
}
.hero-title-header {
  font-weight: 400;
  font-size: 6.25vw;
  margin-bottom: 0;
  line-height: 1;
}
.hero-title-firstline {
  color: transparent;
  -webkit-text-stroke: 1px #fff;
}
.hero-title-secondline {
  color: #fff;
}
.play-button-container {
  position: absolute;
  top: 25%;
  left: 12%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.play-film-text {
  color: #ffffff;
  opacity: 0.6;
  margin: 0;
  margin-left: 20px;
}
.hero-video {
  width: 90vw;
  height: 100vh;
  position: relative;
  margin-top: 30vh;
}
.hero-video-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 400px;
  overflow: hidden;
}
.hero-video-embed-modal {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.hero-section-video-modal {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}
.hero-section-modal-video-container {
  width: 70%;
}
.close-modal-container {
  color: #fff;
  font-weight: 900;
  font-size: 24px;
  position: absolute;
  top: 50px;
  right: 50px;
  cursor: pointer;
  width: 30px;
  text-align: center;
}
@media (max-width: 850px) {
  .hero-video-container {
    height: 50.625vw;
  }
  .play-button-container {
    top: 15vw;
  }
}
