.SearchResultsPackageCard {
  display: flex;
  margin: 30px 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.06);
  padding-bottom: 30px;
  cursor: pointer;
}

.SearchResultsPackageCard .package-image-container {
  width: 300px;
  height: 225px;
}

.SearchResultsPackageCard .package-image-container .package-image {
  width: 300px;
  height: 225px;
}

.SearchResultsPackageCard .package-information {
  margin-left: 20px;
}

.SearchResultsPackageCard .package-information .search-results-packages-description {
  height: 65px;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.SearchResultsPackageCard
  .package-information
  .search-results-packages-description::-webkit-scrollbar {
  display: none;
}

.SearchResultsPackageCard .package-information .package-details {
  display: flex;
  justify-content: space-between;
}

.SearchResultsPackageCard .package-image-container .package-details.mobile {
  display: none;
}

.SearchResultsPackageCard:hover .package-details .duration-container .info,
.SearchResultsPackageCard:hover .package-details .price-container .info {
  color: #ffffff;
}

.SearchResultsPackageCard .package-details .duration-container .info,
.SearchResultsPackageCard .package-details .price-container .info {
  color: #d0d0d0;
}

@media (max-width: 1000px) {
  .SearchResultsPackageCard {
    margin: 15px 20px;
    width: initial;
  }
}

@media (max-width: 600px) {
  .SearchResultsPackageCard {
    flex-direction: column;
    border-bottom: initial;
  }

  .SearchResultsPackageCard .package-image-container {
    width: initial;
    height: 150px;
    display: flex;
    justify-content: space-between;
  }

  .SearchResultsPackageCard .package-image-container .package-image {
    object-fit: cover;
    width: 70%;
    height: 150px;
  }

  .SearchResultsPackageCard .package-information .package-details {
    display: none;
  }

  .SearchResultsPackageCard .package-image-container .package-details.mobile {
    display: inline;
    margin-left: auto;
  }

  .SearchResultsPackageCard .package-information {
    margin-left: 0;
  }

  .SearchResultsPackageCard
    .package-image-container
    .package-details.mobile
    .duration-container
    .label,
  .SearchResultsPackageCard
    .package-image-container
    .package-details.mobile
    .price-container
    .label {
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.04em;
  }

  .SearchResultsPackageCard
    .package-image-container
    .package-details.mobile
    .duration-container
    .info,
  .SearchResultsPackageCard
    .package-image-container
    .package-details.mobile
    .price-container
    .info {
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.01em;
    color: #ffffff;
  }
}

@media (max-width: 350px) {
  .SearchResultsPackageCard {
    margin: 0 15px;
  }
  .SearchResultsPackageCard .package-image-container {
    flex-direction: column;
    height: initial;
  }

  .SearchResultsPackageCard .package-image-container .package-image {
    width: 100%;
    object-fit: cover;
  }

  .SearchResultsPackageCard .package-image-container .package-details.mobile {
    display: flex;
    flex-direction: row;
    margin-left: initial;
    background: #2e456a;
    justify-content: space-between;
    padding: 10px 10px 5px 10px;
    margin-bottom: 0;
  }
}
