.map-container {
  bottom: 0;
  left: 0;
  right: 0;
  height: 400px;
}

.map-marker {
  background-color: #181818;
  color: #fff;
  padding: 5px;
  border: 1px solid rgba(255, 255, 255, 0.4);
}

.map-marker-text {
  font-family: "Rubik", sans-serif;
  margin: 0;
}

@media screen and (max-width: 900px) {
  .map-container {
    height: 200px;
  }
}